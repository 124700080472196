<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>

        <vs-navbar-item index="0">
           <vs-button v-if="navPageIdx==0" :to="{name: 'coach-home'}" type="filled">首页</vs-button>
           <vs-button v-else :to="{name: 'coach-home'}" color="rgba(0,0,0,0)" text-color="rgba(var(--vs-primary),1)" >首页</vs-button>
        </vs-navbar-item>

        <vs-navbar-item index="1">
            <!-- <vs-button :to="{name: 'clients'}" :type="navPageIdx==1?'border':'flat'" >客户</vs-button> -->
           <vs-button v-if="navPageIdx==1" :to="{name: 'clients'}" type="filled">客户</vs-button>
           <vs-button v-else :to="{name: 'clients'}" color="rgba(0,0,0,0)" text-color="rgba(var(--vs-primary),1)" >客户</vs-button>
        </vs-navbar-item>

        <vs-navbar-item index="2">
            <!-- <vs-button :to="{name: 'library'}" :type="navPageIdx==2?'border':'flat'" >资料库</vs-button> -->
           <vs-button v-if="navPageIdx==2" :to="{name: 'library'}" type="filled">资料库</vs-button>
           <vs-button v-else :to="{name: 'library'}" color="rgba(0,0,0,0)" text-color="rgba(var(--vs-primary),1)" >资料库</vs-button>
        </vs-navbar-item>

        <vs-navbar-item index="3">
            <!-- <vs-button :to="{name: 'programs'}" :type="navPageIdx==3?'border':'flat'">标准训练计划</vs-button> -->
           <vs-button v-if="navPageIdx==3" :to="{name: 'programs'}" type="filled">标准训练计划</vs-button>
           <vs-button v-else :to="{name: 'programs'}" color="rgba(0,0,0,0)" text-color="rgba(var(--vs-primary),1)" >标准训练计划</vs-button>
        </vs-navbar-item>

        <vs-navbar-item index="4">
            <!-- <vs-button :to="{name: 'team'}" :type="navPageIdx==4?'border':'flat'" >团队</vs-button> -->
           <vs-button v-if="navPageIdx==4" :to="{name: 'team'}" type="filled">团队</vs-button>
           <vs-button v-else :to="{name: 'team'}" color="rgba(0,0,0,0)" text-color="rgba(var(--vs-primary),1)" >团队</vs-button>
        </vs-navbar-item>



        <vs-spacer></vs-spacer>

        <!-- USER META -->
        <div v-if="this.$store.state.activeUser" class="the-navbar__user-meta flex items-center">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ user_displayName }}</p>
          </div>
          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="con-img ml-3">
              <vs-avatar v-if="activeUserImg" :src="activeUserImg" />
              <vs-avatar v-else :text="user_displayName" />
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile')"><feather-icon icon="UserIcon" svgClasses="w-4 h-4"></feather-icon> <span class="ml-2">账号信息</span></li>
              <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/apps/orgnization')"><feather-icon icon="MailIcon" svgClasses="w-4 h-4"></feather-icon> <span class="ml-2">组织信息</span></li>
                <vs-divider class="m-1"></vs-divider>-->
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"></feather-icon>
                  <span class="ml-2">退出登录</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

// import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue";
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import draggable from "vuedraggable";

export default {
  name: "the-navbar",
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    }
  },
  data() {
    return {
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      unreadNotifications: [
        {
          index: 0,
          title: "New Message",
          msg: "Are your going to meet me tonight?",
          icon: "MessageSquareIcon",
          time: "Wed Jan 30 2019 07:45:23 GMT+0000 (GMT)",
          category: "primary"
        }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    };
  },
  mounted() {
    // this.$refs.btnHome.$el.click();
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown)
        this.showBookmarkPagesDropdown = false;
    }
  },
  computed: {
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    breakpoint() {
      return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
      let ortn = null;
      if (this.sidebarWidth == "default") ortn = "navbar-default";
      else if (this.sidebarWidth == "reduced") ortn = "navbar-reduced";
      else if (this.sidebarWidth) ortn = "navbar-full";
      return ortn;
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      }
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      }
    },

    // PROFILE
    user_displayName() {
      return (
        this.$store.state.activeUser.name
      );
    },
    activeUserImg() {
      return (
        this.$store.state.activeUser.image_url
      );
    },

    ...mapState([
      "navPageIdx"
    ]),
  },
  methods: {
    logout: function() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : "just now");
      }

      return "Just Now";
    },
    outside: function() {
      this.showBookmarkPagesDropdown = false;
    }
  },
  directives: {
    "click-outside": {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },

      unbind: function(el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  },
  components: {
    // VxAutoSuggest,
    // VuePerfectScrollbar,
    // draggable
  }
};
</script>
